@keyframes slideInUp {
    0% {
        opacity: 0;
        transform: translateY(50%);
   }
    100% {
        opacity: 1;
        transform: none;
   }
  }
  @keyframes slideInDown {
    0% {
        opacity: 0;
        transform: translateY(-50%);
   }
    100% {
        opacity: 1;
        transform: none;
   }
  }
  @keyframes slideInleft {
    0% {
        opacity: 0;
        transform: translateX(50%);
   }
    100% {
        opacity: 1;
        transform: none;
   }
  }
  @keyframes slideInRight {
    0% {
        opacity: 0;
        transform: translateX(-50%);
   }
    100% {
        opacity: 1;
        transform: none;
   }
  }
  @keyframes fadeIn {
    0% {
        opacity: 0;
   }
    100% {
        opacity: 1;
   }
  }
  @keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale(0.5);
   }
    100% {
        opacity: 1;
        transform: none;
   }
  }
  @keyframes zoomReverseIn {
    0% {
        opacity: 0;
        transform: scale(1.5);
   }
    100% {
        opacity: 1;
        transform: none;
   }
  }
  @keyframes flipInY {
    0% {
        opacity: 0;
        transform: rotateY(90deg);
   }
    100% {
        opacity: 1;
        transform: none;
   }
  }
  [data-animation] {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    animation-fill-mode: both;
    animation-duration: 1s;
  }
  .animations-disabled, .animations-disabled [data-animation] {
    animation: none !important;
    opacity: 1 !important;
  }
  .slideInUp {
    animation-name: slideInUp;
  }
  .slideInDown {
    animation-name: slideInDown;
  }
  .slideInLeft {
    animation-name: slideInleft;
  }
  .slideInRight {
    animation-name: slideInRight;
  }
  .fadeIn {
    animation-name: fadeIn;
  }
  .fadeOut {
    animation-name: fadeIn;
    animation-direction: reverse;
  }
  .zoomIn {
    animation-name: zoomIn;
  }
  .zoomOut {
    animation-name: zoomIn;
    animation-direction: reverse;
  }
  .zoomReverseIn {
    animation-name: zoomReverseIn;
  }
  .zoomReverseOut {
    animation-name: zoomReverseIn;
    animation-direction: reverse;
  }
  .flipInY {
    animation-name: flipInY;
  }
  .flipOutY {
    animation-name: flipInY;
    animation-direction: reverse;
  }
  
  
  .block {
    height: 5em;
    line-height: 5em;
    width: 10em;
    background: #464646;
    color: #fdfdfd;
    text-align: center;
    margin: 1em auto;
    text-shadow: 0 0 1px #333; /* so one can see fadeBgColor properly */
  }
  
  .animatable {
    
    /* initially hide animatable objects */
    visibility: hidden;
    
    /* initially pause animatable objects their animations */
    -webkit-animation-play-state: paused;   
    -moz-animation-play-state: paused;     
    -ms-animation-play-state: paused;
    -o-animation-play-state: paused;   
    animation-play-state: paused; 
  }
  
  /* show objects being animated */
  .animated {
    visibility: visible;
    
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -ms-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
  
    -webkit-animation-play-state: running;
    -moz-animation-play-state: running;
    -ms-animation-play-state: running;
    -o-animation-play-state: running;
    animation-play-state: running;
  }
  .animatedShip {
    visibility: visible;
    
    /* -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both; */
    
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -ms-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
  
    -webkit-animation-play-state: running;
    -moz-animation-play-state: running;
    -ms-animation-play-state: running;
    -o-animation-play-state: running;
    animation-play-state: running;
  }
  
  /* CSS Animations (extracted from http://glifo.uiparade.com/) */
  @-webkit-keyframes fadeInDown {
      0% {
          opacity: 0;
          -webkit-transform: translateY(-20px);
      }	100% {
          opacity: 1;
          -webkit-transform: translateY(0);
      }
  }
  
  @-moz-keyframes fadeInDown {
      0% {
          opacity: 0;
          -moz-transform: translateY(-20px);
      }
  
      100% {
          opacity: 1;
          -moz-transform: translateY(0);
      }
  }
  
  @-o-keyframes fadeInDown {
      0% {
          opacity: 0;
          -o-transform: translateY(-20px);
      }
  
      100% {
          opacity: 1;
          -o-transform: translateY(0);
      }
  }
  
  @keyframes fadeInDown {
      0% {
          opacity: 0;
          transform: translateY(-20px);
      }
      100% {
          opacity: 1;
          transform: translateY(0);
      }
  }
  
  
  
  @-webkit-keyframes fadeIn {
      0% {
          opacity: 0;
      }
      20% {
          opacity: 0;
      }
      100% {
          opacity: 1;
      }
  }
  
  @-moz-keyframes fadeIn {
      0% {
          opacity: 0;
      }
      20% {
          opacity: 0;
      }
      100% {
          opacity: 1;
      }
  }
  
  @-o-keyframes fadeIn {
      0% {
          opacity: 0;
      }
      20% {
          opacity: 0;
      }
      100% {
          opacity: 1;
      }
  }
  
  @keyframes fadeIn {
      0% {
          opacity: 0;
      }
      60% {
          opacity: 0;
      }
      20% {
          opacity: 0;
      }
      100% {
          opacity: 1;
      }
  }
  @-webkit-keyframes bounceInLeft {
      0% {
          opacity: 0;
          -webkit-transform: translateX(-2000px);
      }
      60% {
          -webkit-transform: translateX(20px);
      }
  
      80% {
          -webkit-transform: translateX(-5px);
      }
  
      100% {
          opacity: 1;
          -webkit-transform: translateX(0);
      }
  }
  
  @-moz-keyframes bounceInLeft {
      0% {
          opacity: 0;
          -moz-transform: translateX(-2000px);
      }
  
      60% {
          -moz-transform: translateX(20px);
      }
  
      80% {
          -moz-transform: translateX(-5px);
      }
  
      100% {
          opacity: 1;
          -moz-transform: translateX(0);
      }
  }
  
  @-o-keyframes bounceInLeft {
      0% {
          opacity: 0;
          -o-transform: translateX(-2000px);
      }
  
      60% {
          opacity: 1;
          -o-transform: translateX(20px);
      }
  
      80% {
          -o-transform: translateX(-5px);
      }
  
      100% {
          opacity: 1;
          -o-transform: translateX(0);
      }
  }
  
  @keyframes bounceInLeft {
      0% {
          opacity: 0;
          transform: translateX(-2000px);
      }
  
      60% {
          transform: translateX(20px);
      }
  
      80% {
          transform: translateX(-5px);
      }
  
      100% {
          opacity: 1;
          transform: translateX(0);
      }
  }
  @-webkit-keyframes bounceInRight {
      0% {
          opacity: 0;
          -webkit-transform: translateX(400px);
      }
  
      60% {
          -webkit-transform: translateX(-20px);
      }
  
      80% {
          -webkit-transform: translateX(5px);
      }
  
      100% {
          opacity: 1;
          -webkit-transform: translateX(0);
      }
  }
  
  @-moz-keyframes bounceInRight {
      0% {
          opacity: 0;
          -moz-transform: translateX(400px);
      }
  
      60% {
          -moz-transform: translateX(-20px);
      }
  
      80% {
          -moz-transform: translateX(5px);
      }
  
      100% {
          opacity: 1;
          -moz-transform: translateX(0);
      }
  }
  
  @-o-keyframes bounceInRight {
      0% {
          opacity: 0;
          -o-transform: translateX(400px);
      }
  
      60% {
          -o-transform: translateX(-20px);
      }
  
      80% {
          -o-transform: translateX(5px);
      }
  
      100% {
          opacity: 1;
          -o-transform: translateX(0);
      }
  }
  
  @keyframes bounceInRight {
      0% {
          opacity: 0;
          transform: translateX(400px);
      }
  
      60% {
          transform: translateX(-20px);
      }
  
      80% {
          transform: translateX(5px);
      }
  
      100% {
          opacity: 1;
          transform: translateX(0);
      }
  }
  @-webkit-keyframes fadeInUp {
      0% {
          opacity: 0;
          -webkit-transform: translateY(20px);
      }	100% {
          opacity: 1;
          -webkit-transform: translateY(0);
      }
  }
  
  @-moz-keyframes fadeInUp {
      0% {
          opacity: 0;
          -moz-transform: translateY(20px);
      }
  
      100% {
          opacity: 1;
          -moz-transform: translateY(0);
      }
  }
  
  @-o-keyframes fadeInUp {
      0% {
          opacity: 0;
          -o-transform: translateY(20px);
      }
  
      100% {
          opacity: 1;
          -o-transform: translateY(0);
      }
  }
  
  @keyframes fadeInUp {
      0% {
          opacity: 0;
          transform: translateY(20px);
      }
  
      100% {
          opacity: 1;
          transform: translateY(0);
      }
  }
  /* @-webkit-keyframes fadeInUpLong {
      0% {
          opacity: 0;
          -webkit-transform: translateY(20px);
      }	100% {
          opacity: 1;
          -webkit-transform: translateY(0);
      }
  }
  
  @-moz-keyframes fadeInUpLong {
      0% {
          opacity: 0;
          -moz-transform: translateY(20px);
      }
  
      100% {
          opacity: 1;
          -moz-transform: translateY(0);
      }
  }
  
  @-o-keyframes fadeInUpLong {
      0% {
          opacity: 0;
          -o-transform: translateY(20px);
      }
  
      100% {
          opacity: 1;
          -o-transform: translateY(0);
      }
  } */
  
  @keyframes fadeInUpLong {
      0% {
          opacity: 0;
          transform: translateY(40px);
      }
  
      100% {
          opacity: 1;
          transform: translateY(0);
      }
  }
  
  @-webkit-keyframes bounceIn {
      0% {
          opacity: 0;
          -webkit-transform: scale(.3);
      }
      50% {
          -webkit-transform: scale(1.05);
      }
  
      70% {
          -webkit-transform: scale(.9);
      }
  
      100% {
          opacity: 1;
          -webkit-transform: scale(1);
      }
  }
  
  @-moz-keyframes bounceIn {
      0% {
          opacity: 0;
          -moz-transform: scale(.3);
      }
  
      50% {
          -moz-transform: scale(1.05);
      }
  
      70% {
          -moz-transform: scale(.9);
      }
  
      100% {
          opacity: 1;
          -moz-transform: scale(1);
      }
  }
  
  @-o-keyframes bounceIn {
      0% {
          opacity: 0;
          -o-transform: scale(.3);
      }
  
      50% {
          -o-transform: scale(1.05);
      }
  
      70% {
          -o-transform: scale(.9);
      }
  
      100% {
          opacity: 1;
          -o-transform: scale(1);
      }
  }
  
  @keyframes bounceIn {
      0% {
          opacity: 0;
          transform: scale(.3);
      }
  
      50% {
          transform: scale(1.05);
      }
  
      70% {
          transform: scale(.9);
      }
  
      100% {
          opacity: 1;
          transform: scale(1);
      }
  }
  @-webkit-keyframes moveUp {
      0% {
          opacity: 1;
          -webkit-transform: translateY(40px);
      }	100% {
          opacity: 1;
          -webkit-transform: translateY(0);
      }
  }
  
  @-moz-keyframes moveUp {
      0% {
          opacity: 1;
          -moz-transform: translateY(40px);
      }
  
      100% {
          opacity: 1;
          -moz-transform: translateY(0);
      }
  }
  
  @-o-keyframes moveUp {
      0% {
          opacity: 1;
          -o-transform: translateY(40px);
      }
  
      100% {
          opacity: 1;
          -o-transform: translateY(0);
      }
  }
  
  @keyframes moveUp {
      0% {
          opacity: 1;
          transform: translateY(40px);
      }
  
      100% {
          opacity: 1;
          transform: translateY(0);
      }
  }
  
  @-webkit-keyframes fadeBgColor {
      0%{
          background:none;
      }
    70%{
          background:none;
      }
      100%{
          background:#464646;
      }
  }
  @-o-keyframes fadeBgColor {
      0%{
          background:none;
      }
    70%{
          background:none;
      }
      100%{
          background:#464646;
      }
  }
  @keyframes fadeBgColor {
      0%{
          background:none;
      }
    70%{
          background:none;
      }
      100%{
          background:#464646;
      }
  }
  
    /* @-webkit-keyframes shipDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
    }	100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
  }
  
  @-moz-keyframes shipDown {
    0% {
        opacity: 0;
        -moz-transform: translateY(-20px);
    }
  
    100% {
        opacity: 1;
        -moz-transform: translateY(0);
    }
  }
  
  @-o-keyframes shipDown {
    0% {
        opacity: 0;
        -o-transform: translateY(-20px);
    }
  
    100% {
        opacity: 1;
        -o-transform: translateY(0);
    }
  } */
  
  @keyframes shipDown {
    0% {
        opacity: 1;
        transform: translateY(-250px);
    }
    80% {
        opacity: 1;
        /* transform: translateY(20px); */
        transform: rotate(10deg);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
  
    }
  }
  
  .animated.animationDelay{
      animation-delay:.4s;
      -webkit-animation-delay:.4s;
  }
  .animated.animationDelayMed{
      animation-delay:1.2s;
      -webkit-animation-delay:1.2s;
  }
  .animated.animationDelayLong{
      animation-delay:1.6s;
      -webkit-animation-delay:1.6s;
  }
  .animated.animationDelay100{
      animation-delay:.1s;
      -webkit-animation-delay:.1s;
  }
  .animated.animationDelay200{
      animation-delay:.2s;
      -webkit-animation-delay:.2s;
  }
  .animated.animationDelay300{
      animation-delay:.3s;
      -webkit-animation-delay:.3s;
  }
  .animated.animationDelay400{
      animation-delay:.4s;
      -webkit-animation-delay:.4s;
  }
  .animated.animationDelay500{
      animation-delay:.5s;
      -webkit-animation-delay:.5s;
  }
  .animated.animationDelay600{
      animation-delay:.6s;
      -webkit-animation-delay:.6s;
  }
  .animated.animationDelay700{
      animation-delay:.7s;
      -webkit-animation-delay:.7s;
  }
  .animated.animationDelay800{
      animation-delay:.8s;
      -webkit-animation-delay:.8s;
  }
  .animated.animationDelay900{
      animation-delay:.9s;
      -webkit-animation-delay:.9s;
  }
  .animated.fadeBgColor {
      -webkit-animation-name: fadeBgColor;
      -moz-animation-name: fadeBgColor;
      -o-animation-name: fadeBgColor;
      animation-name: fadeBgColor;
  }
  .animated.bounceIn {
      -webkit-animation-name: bounceIn;
      -moz-animation-name: bounceIn;
      -o-animation-name: bounceIn;
      animation-name: bounceIn;
  }
  .animated.bounceInRight {
      -webkit-animation-name: bounceInRight;
      -moz-animation-name: bounceInRight;
      -o-animation-name: bounceInRight;
      animation-name: bounceInRight;
  }
  .animated.bounceInLeft {
      -webkit-animation-name: bounceInLeft;
      -moz-animation-name: bounceInLeft;
      -o-animation-name: bounceInLeft;
      animation-name: bounceInLeft;
  }
  .animated.fadeIn {
      -webkit-animation-name: fadeIn;
      -moz-animation-name: fadeIn;
      -o-animation-name: fadeIn;
      animation-name: fadeIn;
  }
  .animated.fadeInDown {
      -webkit-animation-name: fadeInDown;
      -moz-animation-name: fadeInDown;
      -o-animation-name: fadeInDown;
      animation-name: fadeInDown;
  }
  .animatedShip.shipDown {
      -webkit-animation-name: shipDown;
      -moz-animation-name: shipDown;
      -o-animation-name: shipDown;
      animation-name: shipDown;
  }
  .animated.fadeInUp {
      -webkit-animation-name: fadeInUp;
      -moz-animation-name: fadeInUp;
      -o-animation-name: fadeInUp;
      animation-name: fadeInUp;
  }
  .animated.fadeInUpLong {
      -webkit-animation-name: fadeInUpLong;
      -moz-animation-name: fadeInUpLong;
      -o-animation-name: fadeInUpLong;
      animation-name: fadeInUpLong;
  }
  .animated.moveUp {
      -webkit-animation-name: moveUp;
      -moz-animation-name: moveUp;
      -o-animation-name: moveUp;
      animation-name: moveUp;
  }
  /* blog */
  
  .start {
    opacity: 0;
    transform: translateX(-70px);
    transition: 0.5s all ease-in-out;
  }
  .end {
    opacity: 1;
    transform: translateX(0px);
  }
  .start1 {
    opacity: 0;
    transform: translateY(40px);
    transition: 0.5s all ease-in-out;
    transition-delay: 0.3s;
  }
  .end1 {
    opacity: 1;
    transform: translateY(0px);
  }
  .start2 {
    opacity: 0;
    transform: translateX(70px);
    transition: 0.5s all ease-in-out;
    transition-delay: 0.4s;
  }
  .end2 {
    opacity: 1;
    transform: translateX(0px);
  }