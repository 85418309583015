.loginPage {
    background: #fff2f0;
    min-height: 100vh;
    display: flex;justify-content: center;
    align-items: center;
}
.loginCard{
    width:100%;
    max-width: 450px;
    border-radius: 25px;
  background-color: #fff;
  padding: 20px;
}

.loginCard input{
    border-radius:30px;
    background-color: #ebf0fc;
    border-color: #ebf0fc;
    color: #9da3b0;
}

.loginCard .submitBtn{
    border-radius:30px;
    background-color: #a76353;
    color: #fff;
    font-weight: 500;
    padding: 10px;
}

.loginCard #btn{
   position: absolute; 
   bottom: -35px; 
   padding: 5px;
   margin: 0px 55px;
   align-items: center;
   border-radius: 5px
}

.loader4 {
    width:30px;
    height:30px;
    display:inline-block;
    padding:0px;
    border-radius:100%;
    border:5px solid;
    border-top-color:rgb(255, 255, 255);
    border-bottom-color:rgba(255,255,255, 0.3);
    border-left-color:rgb(255, 255, 255);
    border-right-color:rgba(255,255,255, 0.3);
    -webkit-animation: loader4 1s ease-in-out infinite;
    animation: loader4 1s ease-in-out infinite;
 }
 @keyframes loader4 {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
 }
 @-webkit-keyframes loader4 {
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(360deg);}
 }
 .copyClipboard  {
    position: relative;
    cursor: pointer;
 }
 .copyClipboard .copyText.right{
    transform: unset;
    top: -3px;
    right: -126px;
    left: unset;
 }
 .copyClipboard:hover .copyText {
    display: block;
 }
 .copyClipboard .copyText {
     display: none;
    position: absolute;
    padding: 4px 10px;
    border-radius: 6px;
    background: #000;
    width: max-content;
    color: white;
    font-weight: normal;
    transform: translate(-50%,-50%);
    left: 50%;
    top: -17px;
 }
 .adminService .btn{
    float: right;
 }