
@font-face {
  font-family: Didot;
  src: url(./assets/font/didot-fonts-family/didot-fonts-family/Didot\ LT\ Std\ Bold.otf);
}
@font-face {
  font-family: Circular;
  src: url(./assets/font/Circular/Circular/CircularStd-Book.otf);
}
/* @font-face {
  font-family: Didot;
  src: url(./assets/font/didot/Didot-HTF-B06-Bold.ttf);
} */

/* html {
  scroll-behavior: smooth;
} */
h1,h2,h3,h4,h5{
  font-family: Didot;
}

:root {
  --themeBrown:#A76353;
  --themeBg:#FFF2F0;
}
body {
  margin: 0;
}
.dib {
  display: inline-block;
}
.ai-center {
  align-items: center;
}
.jc-center {
  justify-content: center;
}
.smNavbar {
  background: #FAE9E6;
}
.smNavbar .mainContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 790px;
  margin: auto;
}
.smNavbar .mainContent .linkDiv{
  padding: 9px;
}
.smNavbar .mainContent .linkDiv img{
  margin-right: 10px;
}
.smNavbar .mainContent .linkDiv a{
  color: #BC756B;
  text-decoration: none;
}
.smNavbar .mainContent .linkDiv a:hover{
  color: #BC756B;
  /* text-decoration: none; */
}
.navbar {
  background: #FFF2F0;
}
.navbar .navbar-toggler:focus{
  outline: none;
}
.navbar .navbar-toggler i {
  color: var(--themeBrown);
}
.navbar .navbar-brand {
  max-width: 118px;
}

.navbar .navSocial a {
  text-decoration: none;
  transition: all 0.3s;
}
.navbar .navSocial a img:hover {
  text-decoration: none;
  transition: all 0.3s;
  transform: scale(1.1);
}
.navbar .nav-link {
  color: #272727;
  opacity: 0.7;
  font-size: 20px;
  text-transform: uppercase;
}
.navbar .nav-link:hover {
  opacity: 1;
}

.homePage .bannerSec {
  background: var(--themeBg);
  padding: 50px 0;
  padding-bottom: 0;
  position: relative;
  overflow: hidden;
}
.homePage .bannerSec .content{
  padding: 20px 0;
  padding-bottom: 70px;
  position: relative;
  z-index: 2;
}
.homePage .bannerSec h1{
  font-size: 64px;
  color: var(--themeBrown);
}
.homePage .bannerSec .desc{
  font-size: 27px;
  margin-bottom: 30px;
  color: #272727;
  opacity: 0.7;
  font-family: Circular;
}
.homePage .bannerSec .banner{
  position: relative;  
}
.homePage .bannerSec .banner .leaf{
  position: absolute;
    right: -46px;
    z-index: -1;
    bottom: -65px;
    width: 330px;
}
.homePage .bannerSec .banner .headerBg {
  position: absolute;
  left: 28px;
  top: 0;
  z-index: -2;
  max-width: 100%;
  transform: scale(1.2);
}
.homePage .bannerFooter {
  background: url(./assets/images/bannerFooter.png);
  background-size: cover;
  background-position: center;
  height: 100px;
  margin-top: -70px;
  position: relative;
}




.themeBtnDark{
  display: inline-block;
  background: var(--themeBrown);
  /* background: linear-gradient(270deg,var(--themeBrown) 0,var(--themeBrown)) 0 100% transparent no-repeat; */
  background-size: 100% 100%;
  padding: 10px;
  padding: 12px 30px;
  text-transform: uppercase;
  color: white;
  font-size: 20px;
  border: 2px solid var(--themeBrown);
  font-weight: bold;
  transition:all 0.3s;
}
.themeBtnDark:hover,.themeBtnDark:focus {
  background: linear-gradient(270deg,var(--themeBrown) 0,var(--themeBrown)) 0 100% transparent no-repeat;
  background-size: 0% 100%;
  color: var(--themeBrown);
  transition:all 0.3s;
  outline: none;
  text-decoration: none;
}


.themeBtnOutline{
  /* background: transparent; */
  display: inline-block;
  padding: 10px;
  padding: 12px 30px;
  text-transform: uppercase;
  color: rgba(51, 51, 51, 0.7);
  font-size: 20px;
  border: 2px solid rgba(51, 51, 51, 0.7);
  font-weight: bold;
  background: linear-gradient(270deg,var(--themeBrown) 0,var(--themeBrown)) 0 100% transparent no-repeat;
  background-size: 0% 100%;
  transition:all 0.3s;
}
.themeBtnOutline:hover,.themeBtnOutline:hover {

  color: #fff;
  border: 2px solid var(--themeBrown);
  font-weight: bold;
  background: linear-gradient(270deg,var(--themeBrown) 0,var(--themeBrown)) 0 100% transparent no-repeat;
  background-size: 100% 100%;
  transition:all 0.3s;
  outline: none;
  text-decoration: none;
}
.themeHeadingLg {
  font-size: 45px;
  font-weight: bold;
  color: var(--themeBrown);
}
.themeHeadingMd {
  font-size: 40px;
  font-weight: bold;
  color: var(--themeBrown);
}
.themeTextLg {
  font-size: 22px;
  color: rgba(39, 39, 39, 0.7);
  font-family: Circular;
}
.themeTextMd {
  font-size: 18px;
  color: rgba(39, 39, 39, 0.7);
  font-family: Circular;
}
.homePage .whyChooseSec {
  padding: 50px 0;
}
.homePage .missionSec {
  padding: 50px 0;
}
.homePage .ourServiceSec {
  background: var(--themeBg);
  padding: 80px 0 100px 0;
  position: relative;
  overflow: hidden;
}
.homePage .ourServiceSec .serviceCard {
  background: #FFFFFF;
  border: 1px solid #EAC1C5;
  border-radius: 16px;
  padding: 30px 20px;
  height: 100%;
  transition: all 0.3s;
  position: relative;
  top: 0;
}
.homePage .ourServiceSec .serviceCard:hover {
  box-shadow: 0px 13px 16px -13px rgb(0 0 0 / 75%);
  top: -10px;
  transition: all 0.3s;
}
.homePage .ourServiceSec .serviceCard .icoDiv{
  height: 60px;
}
.homePage .ourServiceSec .serviceCard .heading{
  font-family: Didot;
  font-size: 32px;
  color: var(--themeBrown);
  margin: 10px 0;
  font-weight: bold;
}
.homePage .ourServiceSec .serviceCard .readMore{
  padding: 0;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.homePage .ourServiceSec .title{
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
}
.homePage .ourServiceSec .serviceCard .readMore a{
  color: black;
  letter-spacing: 0.1em;
  color: rgba(51, 51, 51, 0.7);
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
}
.homePage .ourServiceSec .serviceCard .readMore i{
  font-size: 20px;
  position: relative;bottom: -2px;
}
.homePage .ourServiceSec .serviceCard .themeTextMd{
  font-size: 19px;
  color: rgba(39, 39, 39, 0.7);
}
.homePage .ourServiceSec .serviceElement{
  position: absolute;
  bottom: -80px;
  right: -60px;
  z-index: 2;
}
.homePage .ourServiceSec .topElement{
  background: url(./assets/images/ourServiceHead.png);
  background-size: cover;
  background-position: center;
  height: 100px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
}
.homePage .ourServiceSec .bottomElement{
  background: url(./assets/images/ourServiceFooter.png);
  background-size: cover;
  background-position: center;
  height: 100px;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1;
}

.homePage .whatClientSays {
  padding: 50px 0; 
}
.homePage .whatClientSays .clentCard{
  border: 1px solid #EAC1C5;
  border-radius: 16px;
  padding: 30px 20px;
  height: 100%;
  transition: all 0.3s;
  position: relative;
  top: 0;
}
.homePage .whatClientSays .clentCard .themeTextMd  {
  min-height: 108px;
}
.homePage .whatClientSays .slick-track  {
  padding-top: 10px !important;
}
.homePage .whatClientSays .clentCard:hover {
  box-shadow: 0px 13px 16px -13px rgb(0 0 0 / 75%);
  top: -10px;
  transition: all 0.3s;
}
.homePage .whatClientSays .clentCard .profileDiv{
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.homePage .whatClientSays .clentCard .profileDiv .imgDiv{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 15px;
}
.homePage .whatClientSays .clentCard .profileDiv .nameMain .name{
  font-size: 26px;
  color: #333333B2;
  font-weight: bold;
  margin: 0;
}
.homePage .whatClientSays .clentCard .profileDiv .nameMain .time{
  margin: 0;
}

.homePage .sceduleSec {
  padding: 50px 0;
  text-align: center;
}
.homePage .sceduleSec .innerCard{
  background: #FFF2F0;
  border: 1px solid #EAC1C5;
  border-radius: 16px;
  padding: 20px 50px;
}

/* footer */
footer {
  padding: 140px 0 80px 0;
  position: relative;
  background: var(--themeBrown);
  color: white;
}
footer .profile .socialDiv a{
  display: inline-block;
  transition: all 0.3s;
}
footer .profile .socialDiv a:hover{
  transform: scale(1.2);
  transition: all 0.3s;
}
footer .footerElement{
  background: url(./assets/images/footerHeader.png);
  background-size: cover;
  background-position: center;
  height: 100px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
}
footer .profile h2 {
  font-size: 28px;
  margin-top: 20px;
  margin-bottom: 5px;
}
footer h6 {
  font-family: Didot;
  font-size: 26px;
  margin-bottom: 25px;
}
.wordBreak {
  word-break: break-all;
}
footer .linkDiv ul{
  list-style: none;
  padding-left: 0;
}
footer .footerLink a{
  text-decoration: none;
  color: white;
  font-size: 18px;
}
footer .footerLink a:hover{
  opacity: 0.8;
}
footer .footerLink p{
  color: white;
  font-size: 18px;
  margin: 0;
}
footer .whatsapp {
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: inline-block;
  cursor: pointer;
  z-index: 10;
}
footer .whatsapp::before {
  content: "";
  border: 2px solid #ecc9c2;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  animation: zoomBig 2s linear infinite;
  -webkit-animation-delay: .85s;
  animation-delay: .85s;
}
footer .copyrightRow {
  text-align: center;
  padding: 14px;
  font-size: 14px;
  padding: 10px;
  background: #8C5244;
  position: absolute;
  width: 100%;
  bottom: 0;
}
footer .copyrightRow a{
  text-decoration: none;
  color: white;
}
footer .copyrightRow a:hover{
  opacity: 0.8;
}
.aboutPage {
  overflow: hidden;
}
.aboutPage .bespokeSec {
  padding: 60px 0;
  text-align: center;
}
.aboutPage .bespokeSec .bespokFlower{
  position: absolute;
  left: -110px;
  top: -31px;
}
.aboutPage .bespokeSec .videoMain {
  margin-top: 35px;
}
.aboutPage .bespokeSec .videoMain video{
  width: 100%;
  height: auto;
  border-radius: 16px;
}

@keyframes zoomBig {
  0%{
      width: 75px;
      height: 75px;
      opacity: 1;
  }
  50%{
      width: 90px;
      height: 90px;
      opacity: 0.8;
  }
  100%{
      width: 100px;
      height: 100px;
      opacity: 0;
  }
}
footer .whatsapp::after {
  content: "";
  border: 2px solid #ecc9c2;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  animation: zoomBig1 2s linear infinite;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
@keyframes zoomBig1 {
  0%{
      width: 75px;
      height: 75px;
      opacity: 1;
  }
  50%{
      width: 90px;
      height: 90px;
      opacity: 0.8;
  }
  100%{
      width: 100px;
      height: 100px;
      opacity: 0;
  }
}
/* footer */

/* about Us page */
.aboutPage .welcomeSec {
  padding: 50px 0;
}
.aboutPage .navbar {
  background: transparent;
}
.aboutPage .welcomeSec .imgdiv {
  position: relative;
  margin-top: 50px;
}
.aboutPage .welcomeSec .imgdiv .headerBg{
  position: absolute;
  left: 28px;
  top: -215px;
  z-index: -2;
  max-width: 100%;
}
.aboutPage .welcomeSec .imgdiv .element{
  position: absolute;
  bottom: -207px;
  right: -125px;
  z-index: 1;
  width: 400px;
}
.aboutPage .ProgesionalSec {
  padding: 50px 0;
}

.lightPinkSec {
  padding: 50px 0;
  text-align: center;
}
.lightPinkSec .innerCard{
  background: #FFF2F0;
  border: 1px solid #EAC1C5;
  border-radius: 16px;
  padding: 20px 50px;
}


/* about Us page */
/* facial page */
.facialPage {}
.facialPage .navbar {
  background: transparent;
}
.facialPage .facialBanner {
  background: url(./assets/images/headerLines.png);
  background-size: cover;
  background-position: center;
  padding: 50px;
  background-color: #FFF2F0;
  position: relative;
}
.facialPage .FacialsSec {
  padding: 50px 0;
}
.facialPage .FacialsSec .acneL{
  position: relative;
}
.facialPage .FacialsSec .acneL .logo{
  max-width: 200px;
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.facialPage .FacialsSec .imgdiv{
  text-align: center;
}
.facialPage .FacialsSec img{
  border-radius: 12px;
}
.facialPage .facialAddOnSec {
  background: #FFF2F0;
  padding: 50px 0;
  margin: 50px 0;
  position: relative;
}
.facialPage .facialAddOnSec .contentDiv{
  margin-bottom: 40px;
}
.facialPage .facialAddOnSec .adOnElement{
  position: absolute;
  top: -145px;
  right: 50px;
}
.facialPage .themeHeadingMd {
  font-size: 35px;
}
.pr {
  position: relative;
}

.facialPage .FacialsSec .waxCategoryMain {
  display: flex;
}
.facialPage .FacialsSec .waxCategoryMain .col1{
  width: 100%;
  max-width: 400px;
  margin-right: 30px;
}
.facialPage .FacialsSec .waxCategoryMain .col2{
  width: 100%;
  max-width: 500px;
  margin-right: 30px;
}
.facialPage .FacialsSec .waxCategoryMain .content{
  display: flex;
  justify-content: space-between;
  font-size: 27px;
}
.facialPage .FacialsSec .waxCategoryMain .content .name{
 color: rgba(51, 51, 51, 0.7);
 font-weight: 500;
}
.facialPage .FacialsSec .waxCategoryMain .content .price{
  color: #A76353;
  font-weight: 600;
  padding-left: 10px;
}
/* facial page */
.facialPage .splendorMain  {
  background: #FFF2F0;
  padding: 30px;
}
.facialPage .splendorMain .logoDiv {
  width: 150px;
}
.facialPage .splendorMain .logoDiv img{
  width: 100%;
}
.facialPage .splendorMain .name {
  margin-bottom: 5px;
}
.facialPage .splendorMain .name img{
  margin-right: 5px;
}
.facialPage .splendorMain p{
  font-size: 12px;
}
.facialPage .splendorMain .cover{
  margin-bottom: 20px;
}
.facialPage .laserContent {
  margin-top: 30px;
}
.themeTextLg span {
  font-weight: bold;
}
.facialPage .FacialsSec .timeElectro p{
  color: var(--themeBrown);
  font-weight: 500;

}
.facialPage .FacialsSec .hybridFulSet {
  max-width: 550px;
}
.policySec .contentMain{
  margin-top: 40px;
}
.policySec .contentMain p{
  color: rgba(39, 39, 39, 0.7);
}
.policySec .contentMain h2{
  color: var(--themeBrown);
  font-size: 32px;
}
.policySec .contentMain .icon{
  width: 60px;
}
.policySec .contentMain .icon img{
  max-width: 100%;
}
.ContactPage .contactUsSec {
  padding: 30px 0;
}
.ContactPage .navbar {
  background: transparent;
}
.ContactPage .contactUsSec .inputRow{
  display: flex;
  margin-bottom: 10px;
}
.ContactPage .contactUsSec .inputRow .col1{
  width: 100%;
}
.ContactPage .contactUsSec label {
  color: rgba(51, 51, 51, 0.7);
  text-transform: capitalize;

}
.ContactPage .contactUsSec input {
  height: 60px;
  font-size: 20px;
  /* color: #D7D7D7; */
  border: 1px solid #D7D7D7;
}
.ContactPage .contactUsSec textarea {
  font-size: 20px;
  /* color: #D7D7D7; */
  border: 1px solid #D7D7D7;
}
.ContactPage .contactUsSec input::placeholder {
  
  color: #D7D7D7;
}
.ContactPage .contactUsSec textarea::placeholder {
  color: #D7D7D7;
}
.ContactPage .contactUsSec .themeBtnDark {
  text-transform: capitalize;
  font-weight: 500;
}

.Toastify__toast-theme--light {
  background: #a76049 !important;
  color: #fff !important; 
  box-shadow: 0 0 7px 4px rgb(167 94 60) !important;
}
.Toastify__progress-bar-theme--light{
  background: #ffffff !important;
}
.Toastify--animate-icon svg {
  fill: #fff !important;
}
.Toastify__close-button svg {
  fill: #fff !important;
}
.Toastify__close-button svg path {
  fill: #fff !important;
}
.Toastify__close-button--light {
  color: #fff !important;
}
.Toastify__toast-container {
  top: 75px !important;
}
.slick-prev:before, .slick-next:before {
  color: #8C5244 !important;
}


@media screen and (min-width:1350px) {
  .homePage .bannerSec .banner{
      transform: scale(1.15);
      position: relative;
      bottom: 0;
      /* height: 100%; */
      right: -25px;
      bottom: -35px
  }
}
@media screen and (min-width:1200px) and (max-width:1400px) {
  .homePage .bannerSec .banner .headerBg {
    left: -10px;
    transform: scale(1.1);
    top: -26px;
  } 
  .aboutPage .bespokeSec .bespokFlower {
    position: absolute;
    left: -60px;
    top: -47px;
  }
}
@media screen and (min-width:1200px) and (max-width:1350px) {
  .homePage .bannerSec .banner{
      transform: scale(1.1);
      right: 0px;
      bottom: -35px;
  }
}
@media screen and (min-width:992px) and (max-width:1200px) {
  .aboutPage .bespokeSec .bespokFlower {
    position: absolute;
    left: -44px;
    top: -12px;
  }
  .homePage .bannerSec .banner .headerBg {
    left: -10px;
    transform: scale(1);
    top: -26px;
    z-index: 0;
  }
  .homePage .bannerSec .banner .leaf {
    right: -23px;
    z-index: -1;
    bottom: -65px;
    width: 291px;
  }
  .homePage .bannerSec .banner {
    position: relative;
    transform: scale(1.4);
    right: 0px;
    bottom: -35px;
  }
  .homePage .bannerSec {
    overflow: hidden;
  }
  .homePage .bannerSec h1{
      font-size: 61px;
  }
  .homePage .bannerSec .desc{
      font-size: 26px;
      line-height: 1.2;
  }
}
@media screen and (min-width:768px) and (max-width:992px) {
  .facialPage .facialAddOnSec .adOnElement {
      top: -145px;
      right: 0px;
      width: 250px;
  }
}
@media screen and (min-width:576px) and (max-width:768px) {
  .facialPage .facialAddOnSec .adOnElement {
      top: -145px;
      right: 0px;
      width: 230px;
  }
  .homePage .bannerSec .desc {
      font-size: 24px;
  }
  .themeTextLg {
      font-size: 18px;
  }
}
@media screen and (max-width:992px) {
  .homePage .bannerSec .banner .headerBg {
    left: -10px;
    transform: scale(1);
    top: -26px;
    z-index: 0;
  }
  .homePage .bannerFooter {
    z-index: 2;
  }
  .homePage .bannerSec .banner .img-fluid {
     position: relative;
     z-index: 1;
  }
  .homePage .bannerSec .banner .leaf{
     z-index: 0;
  }
  .facialPage .FacialsSec .imgdiv {
      margin-top: 30px;
  }
  .aboutPage .ProgesionalSec .imgdiv{
      margin-top: 30px;
  } 
  .smNavbar .mainContent {
      flex-direction: column  ;
  }
  footer .profile {
      margin-bottom: 30px;
  }
  .homePage .whyChooseSec .imgdiv {
      margin-top: 20px;
  }
}
@media screen and (max-width:768px) {
  .facialPage .FacialsSec .waxCategoryMain {
      flex-direction: column;
  }
  .facialPage .FacialsSec .waxCategoryMain .col1 {
      max-width: 100%;
  }
  .facialPage .FacialsSec .waxCategoryMain .col2 {
      max-width: 100%;
  }
}
@media screen and (max-width:576px) {
  .homePage .bannerSec .banner .leaf {
    right: -14px;
    /* z-index: -1; */
    bottom: -23px;
    width: 53%;
  }
  .facialPage .FacialsSec .acneL .logo {
    max-width: 83px;
  }
  .aboutPage .welcomeSec .imgdiv .element {
      bottom: -151px;
      right: -69px;
      width: 288px;
  }
  .homePage .sceduleSec .innerCard {
      padding: 20px 16px;
  }
  .lightPinkSec .innerCard {
      padding: 20px 16px;
  }
  .ContactPage .contactUsSec input {
      height: 50px;
      font-size: 16px; 
  }
  .ContactPage .contactUsSec textarea {
      font-size: 16px;
  }
  .facialPage .splendorMain  {
      padding: 30px 15px;
  }
  .facialPage .FacialsSec .waxCategoryMain .content .name {
      font-size: 20px;
  }
  .facialPage .FacialsSec .waxCategoryMain .content .price {
      font-size: 20px;
  }
  .themeHeadingLg {
      /* font-size: 36px; */
      font-size: 34px;
  }
  .themeHeadingMd {
      /* font-size: 28px; */
      font-size: 26px;
  }
  .facialPage .themeHeadingMd {
      font-size: 28px;
  }
  .facialPage .facialAddOnSec .adOnElement {
      top: -125px;
      right: 0px;
      width: 200px;
  }
  .themeTextLg {
      font-size: 18px;
  }
  .homePage .bannerSec .desc {
      font-size: 20px;
  }
  .homePage .bannerSec h1 {
      font-size: 48px;
  }
  footer .profile {
      text-align: center;
  }
  footer .socialDiv {
      margin-top: 20px;
  }
  footer h6 {
      margin-bottom: 10px;
  }
  footer .footerLink {
      margin-bottom: 30px;
  }
}
@media screen and (max-width:353px) {
  .homePage .bannerSec .desc {
      font-size: 18px;
  }
  .homePage .bannerSec h1 {
      font-size: 40px;
  }
}
@media screen and (max-width:992px)  {
  .homePage .bannerSec {
      padding: 0;
  }
}
